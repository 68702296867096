<template>
  <div>
    <LottieAni
      v-if="isShow"
      ref="ani"
      :aniData="aniJSON"
      :loop="loop"
      :autoplay="autoplay"
      @complete="complete"
      @enterFrame="enterFrame"
    />
  </div>
</template>

<script>
import Lottie from "vue-lottie";
import app from "@/config";
export default {
  name: "AniItem",
  components: { Lottie },
  props: {
    name: {
      type: String,
      default: "",
    },
    loop: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    /** 动画播放速度 */
    setSpeed: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      isShow: false,
      aniJSON: {},
      aniList: [
        app.lottie.p1SceneData,
        app.lottie.p1TextData,
        app.lottie.p2SceneData,
        app.lottie.p2SceneData2,
        app.lottie.p2TextData,
        app.lottie.p3SceneData,
        app.lottie.p3TextData,
        app.lottie.p4SceneData,
        app.lottie.p4TextData,
        app.lottie.p5SceneData,
        app.lottie.p5SceneData2,
        app.lottie.p5TextData,
        app.lottie.p6SceneData,
        app.lottie.p6Pointer,
        app.lottie.p6TextData,
        app.lottie.p7SceneData1,
        app.lottie.p7SceneData2,
        app.lottie.p7SceneData3,
        app.lottie.p7SceneData4,
        app.lottie.cloudData,
        app.lottie.hintData,
        app.lottie.btnResultData,
      ],
    };
  },
  mounted() {
    this.initAni();
  },
  methods: {
    initAni() {
      fetch(this.aniList[this.name])
        .then((resp) => resp.text())
        .then((text) => {
          this.aniJSON = JSON.parse(String(text));
          this.isShow = true;
        });
    },
    complete(anim) {
      this.$emit("complete", anim);
    },
    enterFrame(e, anim) {
      this.$emit("enterFrame", e, anim);
    },
    playAni() {
      this.$refs.ani.playAni();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
