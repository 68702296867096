/*
 * @Author: HuaFeng
 * @Date: 2022-10-29 10:13:16
 * @LastEditTime: 2023-12-13 20:56:52
 * @LastEditors: HuaFeng
 * @Description: 静态资源配置
 */

// 测试桶
// 本地资源版本号
const versions = 202212141540;
const cos = '/'

export default {
  // lottie组件动画
  lottie: {
    p1SceneData: `${cos}lottie/p1SceneData.json?v=${versions}`,
    p1TextData: `${cos}lottie/p1TextData.json?v=${versions}`,

    p2SceneData: `${cos}lottie/p2SceneData.json?v=${versions}`,
    p2SceneData2: `${cos}lottie/p2SceneData2.json?v=${versions}`,
    p2TextData: `${cos}lottie/p2TextData.json?v=${versions}`,

    p3SceneData: `${cos}lottie/p3SceneData.json?v=${versions}`,
    p3TextData: `${cos}lottie/p3TextData.json?v=${versions}`,

    p4SceneData: `${cos}lottie/p4SceneData.json?v=${versions}`,
    p4TextData: `${cos}lottie/p4TextData.json?v=${versions}`,

    p5SceneData: `${cos}lottie/p5SceneData.json?v=${versions}`,
    p5SceneData2: `${cos}lottie/p5SceneData2.json?v=${versions}`,
    p5TextData: `${cos}lottie/p5TextData.json?v=${versions}`,

    p6SceneData: `${cos}lottie/p6SceneData.json?v=${versions}`,
    p6TextData: `${cos}lottie/p6TextData.json?v=${versions}`,
    p6Pointer: `${cos}lottie/p6Pointer.json?v=${versions}`,

    p7SceneData1: `${cos}lottie/p7SceneData1.json?v=${versions}`,
    p7SceneData2: `${cos}lottie/p7SceneData2.json?v=${versions}`,
    p7SceneData3: `${cos}lottie/p7SceneData3.json?v=${versions}`,
    p7SceneData4: `${cos}lottie/p7SceneData4.json?v=${versions}`,
    
    cloudData: `${cos}lottie/cloud.json?v=${versions}`,
    hintData: `${cos}lottie/hint.json?v=${versions}`,
    btnResultData: `${cos}lottie/btnResultData.json?v=${versions}`,
  },
};
