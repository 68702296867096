<!--
 * @Author: HuaFeng
 * @Date: 2023-12-08 10:17:56
 * @LastEditTime: 2023-12-08 14:52:16
 * @LastEditors: HuaFeng
 * @Description: 答题弹窗
-->
<template>
  <div class="answer-popup" :class="{ show: isShowAnswer }">
    <div class="bg-top">
      <img
        class="img-item"
        :src="$store.state.imgData['popup-bg-top']"
        alt=""
      />
    </div>
    <div class="bg-base">
      <img
        class="img-item"
        :src="$store.state.imgData['popup-bg-base']"
        alt=""
      />
    </div>
    <div class="answer-popup-inner">
      <div class="answer-title">{{ answerData?.title || "" }}</div>
      <div class="answer-list">
        <div
          v-for="(item, index) in answerData.list"
          :key="index"
          class="answer-item"
          :class="{ active: current === index }"
          @click="answer(index)"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnswerPopup",
  props: {
    /** 是否显示答题 */
    isShowAnswer: {
      type: Boolean,
      default: false,
    },
    answerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      current: -1,
    };
  },
  mounted() {},
  methods: {
    answer(index) {
      if (this.current != -1) return;
      this.current = index;

      setTimeout(() => {
        this.$emit("answer", index);
      }, 300);
    },
  },
};
</script>
<style lang="scss" scoped src="./index.scss"></style>