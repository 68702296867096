import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    imgData: {},
  },
  mutations: {
    saveImgList(state, imgData) {
      state.imgData = imgData;
    },
  }
})