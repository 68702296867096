<!--
 * @Author: HuaFeng
 * @Date: 2023-12-05 11:32:41
 * @LastEditTime: 2023-12-13 21:14:03
 * @LastEditors: HuaFeng
 * @Description: 第六页
-->
<template>
  <div class="page-item">
    <div class="page-bg">
      <img class="img-item" :src="$store.state.imgData['p6-bg']" alt="" />
    </div>
    <!-- 动效 - 场景 -->
    <div class="ani-item ani-item-scene">
      <AniItem name="12" autoplay loop />
    </div>
    <!-- 动效 - 文案 -->
    <div class="ani-item ani-item-wrap">
      <div class="ani-item-text">
        <AniItem @complete="aniEnd" autoplay name="14" />
      </div>
    </div>
    <!-- 动效 - 指针 -->
    <div class="ani-item ani-item-scene">
      <AniItem name="13" autoplay />
    </div>
    <!-- 按钮 -->
    <div class="ani-item ani-item-btn" @click="goResult">
      <img class="img-item" :src="$store.state.imgData['btn-p6']" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageSix",
  props: {
    isShowBtn: {
      type: Boolean,
      default: false,
    },
  },
  
  methods: {
    aniEnd() {
      this.$emit("aniEnd");
    },
    goResult() {
      this.$emit("goResult");
    },
  },
};
</script>
<style lang="scss" scoped src="./index.scss"></style>