<!--
 * @Author: HuaFeng
 * @Date: 2023-12-01 16:24:27
 * @LastEditTime: 2023-12-11 14:46:26
 * @LastEditors: HuaFeng
 * @Description: 加载页
-->
<template>
  <div class="page-loading">
    <div class="loading-wrap">
      <div class="loading-progress-num">
        {{ progressIndex + "%" }}
      </div>
      <div class="loading-progress-box">
        <div class="loading-progress-wrap">
          <div
            class="loading-progress-inner"
            :style="[{ width: progressIndex + '%' }]"
          >
            <div class="loading-icon">
              <img class="item-img" :src="iconLoading" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="loading-progress-text">加载中...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageLoading",

  data() {
    // 存储桶
    const cos = "@/assets/";
    //这里存放数据
    return {
      allList: [
        {
          url: "icon-music",
          type: "png",
        },
        {
          url: "icon-music-stop",
          type: "png",
        },
        {
          url: "hint-glide",
          type: "gif",
        },
        {
          url: "p1-decorate",
          type: "png",
        },
        {
          url: "p1-bg",
          type: "jpg",
        },
        {
          url: "p2-decorate",
          type: "png",
        },
        {
          url: "p2-bg",
          type: "jpg",
        },
        {
          url: "p3-decorate",
          type: "png",
        },
        {
          url: "p3-bg",
          type: "jpg",
        },
        {
          url: "p4-bg",
          type: "jpg",
        },
        {
          url: "p5-bg",
          type: "jpg",
        },
        {
          url: "p6-bg",
          type: "jpg",
        },
        {
          url: "end-bg1",
          type: "jpg",
        },
        {
          url: "end-bg2",
          type: "jpg",
        },
        {
          url: "end-bg3",
          type: "jpg",
        },
        {
          url: "end-bg4",
          type: "jpg",
        },
        {
          url: "btn-rule",
          type: "png",
        },
        {
          url: "result-text",
          type: "png",
        },
        {
          url: "icon-loading",
          type: "png",
        },
        {
          url: "light",
          type: "png",
        },
        {
          url: "popup-bg-top",
          type: "png",
        },
        {
          url: "popup-bg-base",
          type: "png",
        },
        {
          url: "btn-p6",
          type: "png",
        },
      ],
      testIndex: 0,
      iconLoading: require(`@/assets/img/icon-loading.png`,)
    };
  },
  computed: {
    progressIndex() {
      return Math.floor((100 / this.allList.length) * this.testIndex);
    },
  },
  mounted() {
    this.initData();
  },
  //方法集合
  methods: {
    initData() {
      let imgData = {};
      const _cacheList = [];
      this.allList.forEach((item) => {
        _cacheList.push({
          url: require(`@/assets/img/${item.url}.${item.type}`),
          name: item.url,
        });
      });

      _cacheList.forEach((item) => {
        let image = new Image();
        image.onload = () => {
          this.testIndex++;
          if (this.testIndex === _cacheList.length) {
            _cacheList.forEach((item) => {
              imgData[item.name] = item.url;
            });
            this.$store.commit("saveImgList", imgData);
            // 为了让加载页体验更好 - 稍微显示一会儿
            setTimeout(() => {
              this.$emit("loadingEnd");
            }, 500);
          }
        };
        image.src = item.url;
      });
    },
  },
};
</script>
<style lang="scss" scoped src="./index.scss"></style>