<template>
  <div id="app">
    <!-- 背景音乐icon -->
    <v-touch
      class="home-wrap"
      :class="{
        'page-result': currentIndex === 7,
        'page-p6': currentIndex === 6,
      }"
      @swipeup="slideCut"
      @swipedown="slideCutDown"
    >
      <PageLoading v-if="currentIndex === 0" @loadingEnd="loadingEnd" />
      <PageOne
        v-else-if="currentIndex === 1"
        @aniEnd="textAniEnd"
        @answer="answer"
        :isShowAnswer="isShowAnswer"
        :answerData="answerData[0]"
      />
      <PageTwo v-else-if="currentIndex === 2" @aniEnd="textAniEnd" />
      <PageThree v-else-if="currentIndex === 3" @aniEnd="textAniEnd" />
      <PageFour
        v-else-if="currentIndex === 4"
        @aniEnd="textAniEnd"
        @answer="answer"
        :isShowAnswer="isShowAnswer"
        :answerData="answerData[1]"
      />
      <PageFive
        v-else-if="currentIndex === 5"
        @aniEnd="textAniEnd"
        @answer="answer"
        :isShowAnswer="isShowAnswer"
        :answerData="answerData[2]"
      />
      <PageSix
        v-else-if="currentIndex === 6"
        @aniEnd="textAniEnd"
        @goResult="goResult"
        :isShowBtn="!isAniCutLock"
      />
      <PageResult v-else-if="currentIndex === 7" :endIndex="endIndex" />
    </v-touch>
    <!-- 云动效 -->
    <div class="ani-item ani-item-cloud">
      <AniItem
        ref="cloudRef"
        @enterFrame="enterFrame"
        @complete="cloudAniEnd"
        name="19"
        setSpeed="1.5"
      />
    </div>
    <PlayBgMusic v-if="currentIndex > 0" ref="PlayBgMusic" />
    <div
      class="ani-item-hint"
      :class="{
        hide: isAniCutLock || [0, 1, 4, 5, 6, 7].includes(currentIndex),
      }"
    >
      <AniItem name="20" loop autoplay />
    </div>
  </div>
</template>

<script>
import { useSet } from "@/utils/index";
export default {
  name: "App",
  data() {
    return {
      // 当前页面下标
      currentIndex: 0,
      // 总滑屏页面数
      allIndex: 6,
      // 切换页面动效锁
      isAniCutLock: false,
      /**
       * 结果页状态:
       * 0 => 眼里有光
       * 1 => 运气爆棚
       * 2 => 逆风翻盘
       * 3 => 锦鲤附体
       */
      endIndex: 0,
      // 是否上滑
      isDown: false,
      // 是否显示答题弹窗
      isShowAnswer: false,
      // 当前用户答题数据
      answerResult: -1,
      answerData: [
        {
          title: "2023年，你的投资结果如何？",
          list: [
            { text: "洞察市场，先机尽在掌握" },
            { text: "落袋为安，收获稳稳的幸福" },
            { text: "小有收获，收益更上一层楼" },
            { text: "起伏反复，小迷糊越挫越勇" },
          ],
        },
        {
          title: "2024你的提升小计划是？",
          list: [
            { text: "读万卷书不如行万里路" },
            { text: "书中自有黄金屋" },
            { text: "持续成长，做时间的朋友" },
            { text: "身体是革命的本钱" },
          ],
        },
        {
          title: "新的一年，你的市场展望？",
          list: [
            { text: "龙腾四海，财运滚滚" },
            { text: "春暖花开，复苏在望" },
            { text: "政策加码，潜力无限" },
          ],
        },
      ],
    };
  },
  methods: {
    /** 静态图片加载完成 - 显示页面一 */
    loadingEnd() {
      this.currentIndex = 1;
    },

    /** 文字动画结束  */
    textAniEnd() {
      // 如果是答题页面 - 额外显示答题弹窗
      if ([1, 4, 5].includes(this.currentIndex)) {
        this.isShowAnswer = true;
      }

      // 解锁屏幕禁止下滑限制
      this.isAniCutLock = false;
    },

    /** 云动画结束 - 解锁屏幕禁止下滑限制 */
    cloudAniEnd() {
      // 如果想要文字动效结束才能切换页面注释掉赋值即可
      this.isAniCutLock = false;
    },

    /** 监听动画 - 触发切换页面 */
    enterFrame(e, item) {
      // 获取当前云朵切换动画 - 当播放到第37帧的时候切换页面（被云朵动画遮挡切换让用户无感知）
      if (e.currentTime >= 37 && !this.isPageLock) {
        this.isPageLock = true;
        this.isShowAnswer = false;

        if (this.isDown) {
          this.currentIndex--;
        } else {
          this.currentIndex++;
        }
      }
    },

    /** 用户滑动 - 执行动画 */
    slideCut() {
      // 禁止滑动的页面
      const noSlide = [0, 1, 4, 5].includes(this.currentIndex);
      if (this.allIndex <= this.currentIndex || noSlide) {
        return;
      }
      this.isDown = false;
      if (this.isAniCutLock) return;
      if (this.currentIndex === 1) {
        this.$refs.PlayBgMusic.touchPlay();
      }
      this.isAniCutLock = true;
      this.isPageLock = false;
      this.$refs.cloudRef.playAni();
    },

    /** 用户滑动 - 上滑 */
    slideCutDown() {
      /** 如果需要开启上滑打开注释即可↓ */
      // if (this.currentIndex === 1) {
      //   return;
      // }
      // if (this.isAniCutLock) return;
      // this.isAniCutLock = true;
      // this.isPageLock = false;
      // this.$refs.cloudRef.playAni();
      // this.isDown = true;
    },

    /** 点击按钮进入结果页 */
    goResult() {
      this.isDown = false;
      this.isAniCutLock = true;
      this.isPageLock = false;
      this.$refs.cloudRef.playAni();

      if (this.answerResult === 0) {
        // 选择a较多 -> 前往结果页 - 锦鲤附体
        this.endIndex = 3;
      } else if (this.answerResult === 1) {
        // 选择b较多 -> 前往结果页 - 眼里有光
        this.endIndex = 0;
      } else if (this.answerResult === 2) {
        // 选择c较多 -> 前往结果页 - 运气爆棚
        this.endIndex = 1;
      } else if (this.answerResult === 3) {
        // 选择c较多 -> 前往结果页 - 逆风翻盘
        this.endIndex = 2;
      }
    },

    /** 答题后切换页面 */
    answer(index) {
      if (this.answerResult === -1) {
        // 获取答题数据
        this.answerResult = index;
      }
      this.isDown = false;
      // 切换页面动效
      this.isAniCutLock = true;
      this.isPageLock = false;
      // 执行切换页面动效
      this.$refs.cloudRef.playAni();
    },
  },
};
</script>

<style lang="scss" src="./styles/index.scss"></style>
