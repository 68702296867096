<template>
  <div class="play-music">
    <!-- 如果嫌弃一直有背景音乐可注释掉 -> autoplay -->
    <audio
      :src="audioURL"
      ref="audioRef"
      @ended="overAudio"
      @pause="onPause"
      @play="onPlay"
      loop
      autoplay
    ></audio>
    <div class="play-music-icon" :class="{ play: isPlay }" @click="clickPlay">
      <img
        class="icon-item icon-item-play"
        :src="$store.state.imgData['icon-music']"
        alt=""
      />
      <img
        class="icon-item icon-item-stop"
        :src="$store.state.imgData['icon-music-stop']"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PlayBgMusic",
  data() {
    return {
      // 实例化一个音频播放器对象
      audio: null,
      isPlay: false,
      audioURL: require("@/assets/audio/bg.mp3"),
    };
  },
  methods: {
    clickPlay() {
      if (this.isPlay) {
        this.$refs.audioRef.pause();
        this.isPlay = false;
      } else {
        this.$refs.audioRef.play();
        this.isPlay = true;
      }
    },
    touchPlay() {
      // this.$refs.audioRef.play();
    },
    /** 音频播放 */
    onPlay() {
      this.isPlay = true;
    },
    /** 音频暂停 */
    onPause() {
      this.isPlay = false;
    },
    /** 音频结束 */
    overAudio() {
      this.isPlay = false;
    },
  },
};
</script>
<style lang="scss" scoped src="./index.scss"></style>