<!--
 * @Author: HuaFeng
 * @Date: 2023-12-05 10:27:46
 * @LastEditTime: 2023-12-13 21:07:05
 * @LastEditors: HuaFeng
 * @Description: 第五页
-->
<template>
  <div class="page-item">
    <div class="page-bg">
      <img class="img-item" :src="$store.state.imgData['p5-bg']" alt="" />
    </div>
    <!-- 动效 - 场景 -->
    <div class="ani-item ani-item-scene">
      <AniItem name="10" autoplay loop />
    </div>
    <!-- 动效 - 场景 -->
    <div class="ani-item ani-item-scene">
      <AniItem name="9" autoplay />
    </div>
    <!-- 动效 - 文案 -->
    <div class="ani-item ani-item-wrap">
      <div class="ani-item-text">
        <AniItem @complete="aniEnd" autoplay name="11" />
      </div>
    </div>
    <AnswerPopup
      :isShowAnswer="isShowAnswer"
      :answerData="answerData"
      @answer="answer"
    />
  </div>
</template>

<script>
export default {
  name: "PageFive",
  props: {
    /** 是否显示答题 */
    isShowAnswer: {
      type: Boolean,
      default: false,
    },
    /** 答题数据 */
    answerData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    aniEnd() {
      this.$emit("aniEnd");
    },
    answer(index) {
      this.$emit("answer", index);
    },
  },
};
</script>
<style lang="scss" scoped src="./index.scss"></style>