import Vue from 'vue'
import App from './App.vue'
import store from './store'

import VueTouch from "vue-touch";
Vue.config.productionTip = false
import plugin from '@/components'
Vue.use(plugin)
Vue.use(VueTouch, {name: "v-touch"});
new Vue({
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
