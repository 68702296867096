<template>
  <div>
    <lottie :options="defaultOptions" @animCreated="handleAnimation" />
  </div>
</template>

<script>
import Lottie from "vue-lottie";
export default {
  name: "LottieAni",
  components: { Lottie },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    aniData: {
      type: Object,
      default: () => {},
    },
    loop: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    /** 动画播放速度 */
    setSpeed: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      defaultOptions: {
        renderer: "canvas",
        loop: this.loop,
        autoplay: this.autoplay,
        animationData: this.aniData,
        ...this.options,
      },
      anim: {},
    };
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      this.anim.addEventListener("complete", () => {
        this.$emit("complete", this.anim);
      });
      this.anim.addEventListener("enterFrame", (e) => {
        this.$emit("enterFrame", e, this.anim);
      });
      this.anim.addEventListener("loopComplete", (e) => {
        this.$emit("loopComplete", e, this.anim);
      });
    },
    playAni() {
      this.anim.setSpeed(this.setSpeed);
      this.anim.goToAndPlay(0);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
