<!--
 * @Author: HuaFeng
 * @Date: 2023-12-05 15:06:52
 * @LastEditTime: 2023-12-13 21:00:30
 * @LastEditors: HuaFeng
 * @Description: 结果页
-->
<template>
  <div v-if="endIndex >= 0" class="page-item">
    <div class="page-bg page-bg-wrap">
      <div class="page-bg-inner">
        <div v-if="endIndex === 0" class="page-bg-decorate">
          <img class="img-item" :src="$store.state.imgData['light']" alt="" />
        </div>
        <img
          class="img-item"
          :src="$store.state.imgData['end-bg' + (endIndex + 1)]"
          alt=""
        />
      </div>
    </div>
    <div class="ani-item ani-item-scene">
      <AniItem v-if="ani[endIndex]" :name="ani[endIndex]" autoplay loop />
    </div>
    <div v-if="false" class="result-btn">
      <img class="img-item" :src="$store.state.imgData['btn-rule']" alt="" />
    </div>
    <div class="result-content">
      <div class="result-text" :class="[`result-text${endIndex + 1}`]">
        <img
          class="img-item"
          :src="$store.state.imgData['result-text']"
          alt=""
        />
      </div>
      <!-- 动效 - 按钮 -->
      <div class="ani-item ani-item-btn" @click="goMore">
        <AniItem name="21" />
      </div>
    </div>
  </div>
</template>

<script>
// 按钮动效文件
// import btnData from "@/assets/lottie/btnResultData.json";
export default {
  name: "PageResult",
  props: {
    endIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      ani: ['15', '16', '17', '18'],
    };
  },
  methods: {
    /** 点击了解更多 */
    goMore() {
      console.log("你点击了了解更多 :>> 🙋🏽‍♀️");
    },
  },
};
</script>
<style lang="scss" scoped src="./index.scss"></style>