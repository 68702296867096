<!--
 * @Author: HuaFeng
 * @Date: 2023-12-04 10:03:02
 * @LastEditTime: 2023-12-13 20:33:43
 * @LastEditors: HuaFeng
 * @Description: 第二页
-->
<template>
  <div class="page-item">
    <div class="page-bg">
      <img class="img-item" :src="$store.state.imgData['p2-bg']" alt="" />
    </div>
    <!-- 动效 - 场景 -->
    <div class="ani-item ani-item-scene">
      <AniItem name="2" autoplay loop />
    </div>
    <!-- 动效 - 文案 -->
    <div class="ani-item ani-item-wrap">
      <div class="ani-item-text">
        <AniItem @complete="aniEnd" autoplay name="4" />
      </div>
    </div>
    <!-- 动效 - 场景 -->
    <div class="ani-item ani-item-scene">
      <AniItem name="3" autoplay loop />
    </div>
    <div class="page-decorate">
      <img class="img-item" :src="$store.state.imgData['p2-decorate']" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTwo",
  methods: {
    aniEnd() {
      this.$emit("aniEnd");
    },
  },
};
</script>
<style lang="scss" scoped src="./index.scss"></style>